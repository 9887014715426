import {sample} from "effector";
import {downloadSectorsEv, SectorsGate} from "./events.js";
import {$sectorsList} from "./stores.js";
import {downloadSectorsDataFx, getSectorsDataFx} from "./effects.js";

$sectorsList.on(getSectorsDataFx.doneData, (_, payload) => payload)
	.reset(SectorsGate.close)

sample({
	clock: SectorsGate.state,
	filter: (gate) => Object.hasOwn(gate, 'search') && gate.sector && Object.hasOwn(gate, 'sector'),
	target: getSectorsDataFx
})

sample({
	clock: downloadSectorsEv,
	target: downloadSectorsDataFx
})
