import {createEffect} from "effector";
import {api} from "../../api/api.js";
import queryString from "query-string";

export const getSectorsDataFx = createEffect()
	.use(async ({sector, search}) => (await api().get(`/cert-category/?sector=${sector}${search.length > 0 ? `&${search}` : ''}`)).data)

export const downloadSectorsDataFx = createEffect()
	.use(async ({sector, search}) => {
		const query = queryString.stringify(search)
		const file = await api().get(`/cert-category/export?sector=${sector}${query.length > 0 ? `&${query}` : ''}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = name
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})
