import {Menu} from 'antd';
import {useStore} from 'effector-react';
import {$isAllowed} from '../../../models/profileModel/index.js';
import {useMemo} from 'react';
import {Link} from 'react-router-dom';

const NavMenu = () => {
    const perms = useStore($isAllowed)

    const navMenu = useMemo(() => {
        const menu = [
            {
                key: 'lots',
                label: <Link className={'white_font_color white_font_color:active'} to={'/lots'}>Лоты</Link>
            }
        ]

        if (!perms.investor && !menu.some(i => i.key === 'matches')) {
            menu.push({
                key: 'matches',
                label: <Link className={'white_font_color white_font_color:active'} to={'/matches'}>Совпадения</Link>
            })
        }

        if (!perms.supplier && !menu.some(i => i.key === 'certs')) {
            menu.push({
                key: 'certs',
                label: <Link className={'white_font_color white_font_color:active'} to={'/certificates'}>Сертификаты</Link>
            })
        }

        if ((perms.chamber || perms.admin || perms.gov || perms.investor || perms.buyer) && !menu.some(i => i.key === 'analytics')) {
            menu.push({
                key: 'analytics',
                // label: <Link to={'/analytics'} className={'white_font_color white_font_color:active'}>Аналитика</Link>,
                label: <span className={'white_font_color white_font_color:active'}>Аналитика</span>,
                children: [
                    {
                        key: 'analytics-dashboard',
                        label: <Link to={'/analytics'} className={'white_font_color white_font_color:active'}>Дашборд</Link>
                    },
                    {
                        key: 'analytics-model',
                        label: <Link to={'/analytics-model'} className={'white_font_color white_font_color:active'}>Модель Приоритизации</Link>
                    }
                ]
            })
        }

        if(perms.admin && !menu.some(i => i.key === 'sectors')) {
            menu.push({
                key: 'sectors',
                label: <span className={'white_font_color white_font_color:active'}>Товары ОТП по секторам экономики</span>,
                children: [
                    {
                        key: 'sectors-construction',
                        label: <Link to={'/sectors/construction'} className={'white_font_color white_font_color:active'}>Сектор строительства</Link>
                    },
                    {
                        key: 'sectors-oil-and-gas',
                        label: <Link to={'/sectors/oil-and-gas'} className={'white_font_color white_font_color:active'}>Нефтегазовый сектор</Link>
                    },
                    {
                        key: 'sectors-public-utilities',
                        label: <Link to={'/sectors/public-utilities'} className={'white_font_color white_font_color:active'}>Сектор энергетики и жилищнокоммунального хозяйства</Link>
                    },
                ]
            })
        }

        return menu
    }, [perms])

    return <Menu
        id='header-dropdown'
        theme={'dark'}
        items={navMenu}
        mode={'horizontal'}
        style={{
            width: '100%',
            backgroundColor: '#4328af',

        }}
    />
}

export default NavMenu
