import {Button, Tooltip} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useStore, useUnit} from "effector-react";
import {downloadFilteredMatchesFx} from "../../models/matchesModel/index.js";
import {downloadSectorsDataFx} from "../../models/sectorsModel/index.js";

const DownloadFilteredButton = ({onDownload}) => {
    const matchesLoading = useStore(downloadFilteredMatchesFx.pending)
    const sectorsLoading = useUnit(downloadSectorsDataFx.pending)
    const loading = matchesLoading ||sectorsLoading

    return <Tooltip title={'Скачать выгрузку по примененным фильтрам'} placement={'right'}>
        <Button className={'btn-primary'} icon={<DownloadOutlined />} onClick={() => onDownload()} loading={loading}/>
    </Tooltip>
}

export default DownloadFilteredButton
