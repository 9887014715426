import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
	$sectorsList,
	downloadSectorsDataFx,
	downloadSectorsEv,
	getSectorsDataFx,
	SectorsGate
} from "../../models/sectorsModel/index.js";
import {usePaginationControl} from "../../hooks/pagination-hook.js";
import {Col, Row, Table} from "antd";
import FilterBlock from "../../components/FilterBlock/FilterBlock.jsx";
import {useUnit} from "effector-react";

const sectors_dict = {
	construction: 'Сектор строительства',
	"oil-and-gas": 'Нефтегазовый сектор',
	"public-utilities": 'Сектор энергетики и жилищно-коммунального хозяйства',
}

function Sectors() {
	const {sector} = useParams()
	const [curPage, limit, onChangePagination, search] = usePaginationControl()
	const {count, data} = useUnit($sectorsList)
	const downloadSectors = useUnit(downloadSectorsEv)
	const navigate = useNavigate()
	const loading = useUnit(getSectorsDataFx.pending)

	const filters = [
		{name: 'oked', label: 'ОКЭД', type: 'input'},
		{name: 'oked_name', label: 'Наименование ОКЭД', type: 'input'},
		{name: 'kpved', label: 'КПВЭД', type: 'input'},
		{name: 'kpved_name', label: 'Наименование КПВЭД', type: 'input'},
		{name: 'tnved', label: 'ТНВЭД ЕАЭС', type: 'input'},
		{name: 'tnved_name', label: 'Наименование ТНВЭД ЕАЭС', type: 'input'},
		{name: 'bin', label: 'БИН', type: 'input'},
		{name: 'address', label: 'Регион', type: 'input'},
		{name: 'cert_type', label: 'Сертификат (по ИС/СТ-КЗ)', type: 'select', selectOptions: [{value: 'ИС', label: 'ИС'}, {value: 'СТ-КЗ', label: 'СТ-КЗ'}], mode: 'multiple'},
	]

	const onDownload = (values) => {
		downloadSectors({
			sector: sectors_dict[sector],
			search: values
		})
	}

	const columns = [
		// {
		// 	title: '№',
		// 	width: '5%',
		// 	key: 'id',
		// 	dataIndex: 'id'
		// },
		{
			title: 'Наименование товара по сертификату',
			width: '5%',
			key: 'description_ru',
			dataIndex: 'description_ru'
		},
		{
			title: 'ОКЭД',
			width: '5%',
			key: 'oked',
			dataIndex: 'oked'
		},
		{
			title: 'Наименование ОКЭД',
			width: '5%',
			key: 'oked_name',
			dataIndex: 'oked_name'
		},
		{
			title: 'КПВЭД',
			width: '5%',
			key: 'kpved',
			dataIndex: 'kpved'
		},
		{
			title: 'Наименование КПВЭД',
			width: '5%',
			key: 'kpved_name',
			dataIndex: 'kpved_name'
		},
		{
			title: 'ТНВЭД ЕАЭС',
			width: '5%',
			key: 'tnved',
			dataIndex: 'tnved'
		},
		{
			title: 'Наименование ТНВЭД ЕАЭС',
			width: '5%',
			key: 'tnved',
			dataIndex: 'tnved_name'
		},
		{
			title: 'БИН',
			width: '5%',
			key: 'manufacturer_bin',
			dataIndex: 'manufacturer_bin'
		},
		{
			title: 'Название предприятия',
			width: '5%',
			key: 'manufacturer_name_ru',
			dataIndex: 'manufacturer_name_ru'
		},
		{
			title: 'Регион',
			width: '5%',
			key: 'manufacturer_address_ru',
			dataIndex: 'manufacturer_address_ru'
		},
		// {
		// 	title: 'Сертификат СТ-КЗ',
		// 	width: '5%',
		// 	key: 'cert_id',
		// 	dataIndex: 'cert_id'
		// },
	]

	useEffect(() => {
		if(!sector){
			navigate('/sectors/construction')
		}
	}, [sector]);

	return (
		<>
			<SectorsGate sector={sectors_dict[sector]} search={search.toString()}/>
			<Col span={24}>
				<Row>
					<FilterBlock
						items={filters}
						download={onDownload}
					/>
				</Row>
				<Row style={{marginTop: 24}} id={'sectors-table'}>
					<Table
						dataSource={data}
						columns={columns}
						style={{width: '100%'}}
						loading={loading}
						bordered
						onChange={({current, pageSize}) => onChangePagination(current, pageSize)}
						pagination={{
							position: ['bottomCenter'],
							pageSize: parseInt(limit),
							current: parseInt(curPage),
							total: count
						}}
					/>
				</Row>
			</Col>
		</>
	);
}

export default Sectors;
